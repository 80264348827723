

.roomsheader{
    width: 100%;
    
}


.Label{
    width: 470px;
    height: 505px;
    background-color: black;
    float: left;
    margin-left: 1px;
       
}

.Label2{
    position: relative;
    height: 505px;
    width: 840px !important;
}

.frameholder{
    position: absolute;
    top:10px;
    left:20px;
   

}

.banner{
    width: 840px !important;
    height: 505px !important;
    
}

.carouselBx3{
    font-size:40px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.imgsize{
    height:auto;
}

.formsize51{
    width:440px !important;
    background-color: silver;
    color:black;
    border-color: black;
    border-style: solid;
    text-align: center;
    padding:10px;
}


.bordered{
    margin-left: 30px;
    margin-top: 20px;
    color: gold;
    font-family: Georgia, 'Times New Roman', Times, serif;
    
}


.formdesign2{
    margin-left: 5px;
    width: 100%!important;

}

.formsizeNew{
    height:10px;
    width: 100%!important;
}



/* .objectHolder{
   margin-top: 10px;
    margin-right: -80px;
} */
.butt{
    background-color:rgb(36, 33, 33);
}




.features{
    font-size: 30px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
.roomfeatures{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size:large;
    

}

.icard{
    width: 130px!important;
    height: 130px!important;
    border-radius: 100%;
    margin-left: 10px;
}

.jcard{
    width: 130px!important;
    height: 130px!important;
    border-radius: 100%;
    margin-left: 10px;
}

.kcard{
    width: 126px!important;
    height: 120px!important;
    border-radius: 100%;
    margin-left: 10px;
}

.lcard{
    width: 140px!important;
    height: 126px!important;
    border-radius: 100%;
    margin-left: 10px;
}

.mcard{
    width: 134px!important;
    height: 114px!important;
    border-radius: 100%;
    margin-left: 7px;
}


.rcard{
    width: 150px !important;
    height: 150px !important;
    border-radius: 100%;
    margin-left: 10px;
    
}
.use1{
    height:auto;
}

@media (max-width: 1200px){
       
    .Label{
        width: 820px;
        height: 505px;
        background-color: black;
       
    }

   .formsize51{
        width:700px !important;
        background-color: silver;
        color:black;
        border-color: black;
        border-style: solid;
        text-align: center;
        padding:10px;
    }
    
    .formdesign{
        color: silver;
        margin-left: 30px;
        width: 100%;
        margin-bottom: 20px;
        
    }

    .use1{
        position:relative;
        min-height:1060px;
        
       
    }

    .use2{
        position:absolute;
        top:400px;
        left: -1px;
       
    }

    .use3{
        position:absolute;
        left: -20px;
        top:-10px;
        
    }

    .use4{
        position:absolute;
        top:897px;
        left: 0px;
        width: 820px !important;
        height: 280px !important;
       
    }

    .banner{
        width: 820px !important;
        height: 410px !important;
        
    }

    .carouselBx3{
        font-size:20px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    
    

    
}

@media (max-width: 1000px){
       
    .Label{
        width: 650px;
        height: 505px;
        background-color: black;
       
    }

   .formsize51{
        width:540px !important;
        background-color: silver;
        color:black;
        border-color: black;
        border-style: solid;
        text-align: center;
        padding:10px;
    }
    
    .formdesign{
        color: silver;
        margin-left: 30px;
        width: 100%;
        margin-bottom: 20px;
        
    }

    .use1{
        position:relative;
        min-height:1000px;
        
       
    }

    .use2{
        position:absolute;
        top:350px;
        left: -1px;
       
    }

    .use3{
        position:absolute;
        left: -20px;
        top:-10px;
        
    }

    .use4{
        position:absolute;
        top:850px;
        left: 0px;
        width: 650px !important;
        height: 280px !important;
       
    }

    .banner{
        width: 650px !important;
        height: 345px !important;
        
    }

    .carouselBx3{
        font-size:20px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    
}


@media (max-width: 700px){
       
    .Label{
        width: 375px;
        height: 505px;
        background-color: black;
       
    }

   .formsize51{
        width:340px !important;
        background-color: silver;
        color:black;
        border-color: black;
        border-style: solid;
        text-align: center;
        padding:10px;
    }
    
    .formdesign{
        color: silver;
        margin-left: 30px;
        width: 100%;
        margin-bottom: 20px;
        
    }

    .use1{
        position:relative;
        min-height:1000px;
        
       
    }

    .use2{
        position:absolute;
        top:280px;
        left: -1px;
       
    }

    .use3{
        position:absolute;
        left: -20px;
        top:-10px;
        
    }

    .use4{
        position:absolute;
        top:800px;
        left: 0px;
        width: 380px !important;
        height: 280px !important;
    }

    .banner{
        width: 375px !important;
        height: 280px !important;
        
    }

    .carouselBx3{
        font-size:20px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    
    

    
}

