
.collection{
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;

}

.collection .gallerypix{
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

@media (max-width: 991px){
    .collection{
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2; 
        
    
    }
}

@media (max-width: 480px){
    .collection{
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1; 
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
        
    }
}
.collection .gallerypix:hover{
    filter: opacity(.8);
}

.edit{
    margin-top: 10px;
}
.edit2{
    margin-top: 10px;
    margin-top: 10px;
}

