.size{
    width: 100px !important;
    margin-top: 20px !important;
}

.background2{
    height:450px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: rgb(23, 23, 71);
}

.formdesign2{
    margin-top: 0px;
    background-color: white;
    width: 280px;
    border-style: solid;
    border-color: white;
    border-radius: 5px;
   
    
}